import './Success.css';
import {useLocation} from "react-router-dom";

export default function CampRegisterSuccess() {

    // Use useLocation to access the passed state
    const location = useLocation();
    const KKRN = location.state?.kkrnValue || '---';

    return (
        <>
            <div className={'container'}>
                <div className="text-center">
                    <br></br><br></br>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" color="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <br></br><br></br>
                    <h2 className="text-center">Teşekkürler!</h2>
                    <h2 className="text-center">Kamp için kayıt talebiniz başarılı bir şekilde iletildi.</h2>

                    <h3 className="text-center mt-3">Hayatının en anlamlı kampına hoş geldin !</h3>

                    <h3 className="text-center mt-5" style={{color: '#000000'}}>Kayıt Referans Kodu</h3>
                    <h3 className="text-center mb-3" style={{color: '#000000'}}>{KKRN}</h3>

                    <p className="text-center"> Onay bildirimi e-posta adresinize gönderildi. Lütfen gelen kutunuzu ve spam/junk klasörünüzü kontrol ederek aldığınızdan emin olunuz. </p>
                </div>
            </div>
        </>
    )
}